import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import {
    Body1,
    Box,
    Flex,
    FormFieldError,
    Heading1,
    HorizontalDivider,
    Stack,
    TextInput,
    useNavBar,
} from '@phx/design-system';
import {
    useDobZodSchema,
    useForm,
    useFullNameFieldValidation,
    useHotkeys,
    useNameZodSchema,
} from '@phx/design-system/hooks';
import { PhoneNumberFormatter } from '@phx/utils';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { sendVerificationCodeApi } from '../../api/send-verification-code.api';
import {
    type VerifyAccountApiResponse,
    verifyAccountApi,
} from '../../api/verify-account.api';
import { PageLayout } from '../../components';
import {
    ErrorMap,
    type ErrorMessageTypes,
} from '../../components/alerts/errors/error-map';
import { ConfirmPasswordField } from '../../components/form-fields/ConfirmPasswordField';
import { EmailField } from '../../components/form-fields/EmailField';
import { PasswordField } from '../../components/form-fields/PasswordField';
import { PhoneNumberField } from '../../components/form-fields/PhoneNumberField';
import { SubmitButton } from '../../components/form-fields/SubmitButton';
import { CONSTANTS } from '../../constants';
import { useSignupFormFieldProps } from '../../hooks/use-signup-form-field-props';
import { telemetryInstance } from '../../instrumentation';
import type { CreateAccountFormModel } from '../../models/form.models';
import { routes } from '../../routes';
import type { SignupChallengeQuestionsPageState } from '../sign-up-challenge-questions/SignUpChallengeQuestionsPage';
import type { VerifyAccountPageState } from '../verify-account/VerifyAccountPage';

import { PolicyFooter } from './PolicyFooter';

export type SignUpPageState = {
    backToSignIn?: boolean;
};

const shouldSendOtp = (response: VerifyAccountApiResponse): boolean => {
    const { success, userId, tryAdditionalInfo = false } = response;

    if (!success) {
        return false;
    }

    if (userId) {
        return true;
    }

    return !tryAdditionalInfo;
};

const Signup = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { setBackOverrideState } = useNavBar();
    const state = location?.state as SignUpPageState;
    const appInsights = useAppInsightsContext();

    const dobSchema = useDobZodSchema();
    const nameSchema = useNameZodSchema();

    const form = useForm<CreateAccountFormModel>({
        validateInputOnBlur: true,
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
            phoneNumber: '',
            memberId: '',
            dob: '',
        },
        schema: z.object({
            email: z.string().email({
                message: t('formErrors.formErrorMessage.invalidEmail'),
            }),
            firstName: nameSchema,
            lastName: nameSchema,
            password: z
                .string({ message: t('passwordHelpText.enterPasswordSixChar') })
                .trim()
                .min(CONSTANTS.minimumPasswordLength, {
                    message: t('passwordHelpText.enterPasswordSixChar'),
                })
                .regex(/^\S*$/, {
                    message: t('passwordHelpText.containSpaces'),
                }),
            phoneNumber: z.string().regex(/^\(\d{3}\) \d{3}-\d{4}$/, {
                message: t('formErrors.formErrorMessage.invalidPhoneNumber'),
            }),
            memberId: z.string(),
            dob: dobSchema,
        }),
    });

    const trackForSubmit = useTrackEvent(
        appInsights,
        'SIGNUP_CONTINUE_CLICK',
        {}
    );
    const trackForSignin = useTrackEvent(
        appInsights,
        'SIGNUP_SIGNIN_CLICK',
        {}
    );

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessageType, setErrorMessageType] = useState<
        ErrorMessageTypes | undefined
    >(undefined);

    const ErrorMessage = errorMessageType ? ErrorMap[errorMessageType] : null;

    // Read parameters passed in redirect
    const callBackUrl = searchParams.get('callback') || '/home';
    const userId = searchParams.get('user') ?? undefined;

    const onNavigateToSignIn = () => {
        trackForSignin({ message: 'Sign-in link is clicked' });
        navigate(
            { pathname: routes.signIn, search: window.location.search },
            {
                state: { backToSignUp: true },
            }
        );
    };

    const onNavigateBack = useCallback(() => {
        if (state?.backToSignIn) {
            navigate(-1);
        } else {
            // go to unauth home page
            window.location.replace(import.meta.env.VITE_MYPHX_UNAUTH_URL);
        }
        // Coming from Sign in account by clicking create account link (Go back to sign in)
        // coming with showBack directly from hamburger menu -> create account (Go back to myPhx)
        // coming with showBack true to  hamburger menu -> sign in -> then clicking on create account (go to sign in)
    }, [state]);

    useEffect(() => {
        setBackOverrideState({
            overrideFn: onNavigateBack,
        });

        return () => setBackOverrideState({ overrideFn: null });
    }, [state]);

    const onFormSubmit = async (formData: CreateAccountFormModel) => {
        trackForSubmit({ message: 'Registration form is submitted' });
        setLoading(true);

        const {
            firstName,
            lastName,
            email,
            password,
            phoneNumber,
            dob,
            memberId,
        } = formData;

        const formattedPhoneNumber =
            PhoneNumberFormatter.formatForApi(phoneNumber);
        const trimmedFirstName = firstName?.trim();
        const trimmedLastName = lastName?.trim();

        // Convert mm/dd/yyyy to yyyy-mm-dd
        const dobParts = dob.split('/');
        const dateOfBirth = `${dobParts[2]}-${dobParts[0]}-${dobParts[1]}`;

        const verifyAccountResponse = await verifyAccountApi({
            firstName: trimmedFirstName,
            lastName: trimmedLastName,
            email,
            dateOfBirth,
            phoneNumber: formattedPhoneNumber,
            masterId: userId,
            memberId,
        });

        const {
            userId: userIdFromVerifyResponse,
            error: verifyAccountError,
            tryAdditionalInfo = false,
        } = verifyAccountResponse;

        if (shouldSendOtp(verifyAccountResponse)) {
            const { success: sendOtpSuccess, error: sendOtpError } =
                await sendVerificationCodeApi(formattedPhoneNumber);

            if (sendOtpSuccess) {
                trackForSubmit({ message: 'OTP sent successfully' });
                const verifyPageState: VerifyAccountPageState = {
                    firstName: trimmedFirstName,
                    lastName: trimmedLastName,
                    dateOfBirth,
                    phoneNumber: formattedPhoneNumber,
                    email,
                    password,
                    callBackUrl: callBackUrl ?? '',
                    userId: userId ?? userIdFromVerifyResponse,
                    isSignupFlow: true,
                };
                navigate(routes.verify, {
                    state: verifyPageState,
                });
                return;
            }

            trackForSubmit({ message: 'Failed to send OTP' });
            setLoading(false);
            setErrorMessageType(sendOtpError);
            telemetryInstance.logError({
                source: location.pathname,
                exception: new Error(sendOtpError),
            });

            return;
        }

        if (tryAdditionalInfo) {
            const challengeQuestionsPageState: SignupChallengeQuestionsPageState =
                {
                    firstName: trimmedFirstName,
                    lastName: trimmedLastName,
                    dateOfBirth,
                    phoneNumber: formattedPhoneNumber,
                    email,
                    password,
                    callBackUrl: callBackUrl ?? '',
                    userId: userId ?? userIdFromVerifyResponse,
                };

            trackForSubmit({
                message: 'Potential match found; prompting for more info',
            });
            navigate(routes.signUpChallengeQuestions, {
                state: challengeQuestionsPageState,
            });

            return;
        }

        trackForSubmit({ message: 'Failed to verify account' });
        setLoading(false);
        setErrorMessageType(verifyAccountError);
        telemetryInstance.logError({
            source: location.pathname,
            exception: new Error(verifyAccountError),
        });

        if (verifyAccountError === '409_MultiplePatientsFoundForDemographic') {
            navigate(routes.signUpError);
        }
    };

    useHotkeys([['Enter', () => form.onSubmit(onFormSubmit)]]);

    useFullNameFieldValidation(form);

    const { firstNameProps, lastNameProps, dobProps } =
        useSignupFormFieldProps(form);

    return (
        <PageLayout
            loadingOverlay={loading}
            header={
                !loading
                    ? errorMessageType &&
                      ErrorMessage && (
                          <ErrorMessage onLinkClick={onNavigateToSignIn} />
                      )
                    : null
            }
            heading={
                <Stack gap="sm">
                    <Heading1>{t('signUpPage.aboutYourself')}</Heading1>
                    <Body1>{t('signUpPage.verifyIdentity')}</Body1>
                </Stack>
            }
        >
            <Stack p="lg 0">
                <form
                    id="create-account-form"
                    onSubmit={form.onSubmit(onFormSubmit)}
                >
                    <Stack gap="md">
                        <Stack gap="calc(var(--mantine-spacing-xs) / 2)">
                            <Flex gap="sm">
                                <Box flex={1}>
                                    <TextInput
                                        key={form.key('firstname')}
                                        name="firstname"
                                        label={t('fullNameForm.firstName')}
                                        error={!!form.errors.name}
                                        required
                                        {...firstNameProps}
                                    />
                                </Box>
                                <Box flex={1}>
                                    <TextInput
                                        key={form.key('lastname')}
                                        name="lastname"
                                        label={t('fullNameForm.lastName')}
                                        error={!!form.errors.name}
                                        required
                                        {...lastNameProps}
                                    />
                                </Box>
                            </Flex>
                            {form.errors.name &&
                                typeof form.errors.name === 'string' && (
                                    <FormFieldError
                                        errText={form.errors.name}
                                    />
                                )}
                        </Stack>
                        <Stack gap="xxs">
                            <TextInput
                                key={form.key('dob')}
                                label={t('dateOfBirthForm.dateOfBirth')}
                                name="dob"
                                placeholder="mm/dd/yyyy"
                                mask={CONSTANTS.dobMask}
                                required
                                {...dobProps}
                            />
                        </Stack>
                        <PhoneNumberField form={form} page="SIGNUP" />
                        <EmailField form={form} page="SIGNUP" />
                        <Stack gap="md">
                            <PasswordField form={form} page="SIGNUP" />
                            <ConfirmPasswordField form={form} page="SIGNUP" />
                        </Stack>
                    </Stack>
                </form>
            </Stack>
            <Stack gap="lg">
                <HorizontalDivider />
                <PolicyFooter />
                <SubmitButton form="create-account-form">
                    {t('common.continue')}
                </SubmitButton>
            </Stack>
        </PageLayout>
    );
};

export default Signup;
